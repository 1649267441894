<template>
	<GridSocialIcons
		v-bind="composedProps"
		prevent-links
	/>
</template>

<script>
import GridSocialIcons from '@user/components/grid-components/socialIcons/GridSocialIcons.vue';
import { useGridSocialIcons } from '@user/components/grid-components/socialIcons/useGridSocialIcons';

export default {
	components: { GridSocialIcons },
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	setup(props, context) {
		return { composedProps: useGridSocialIcons(props, context) };
	},
};
</script>
